import React, { useState, useCallback, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  CircularProgress,
  TablePagination,
} from "@mui/material";
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOff';
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import Slide from "@mui/material/Slide";
import DialogContentText from "@mui/material/DialogContentText";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AlertDialogSlide = ({ open, handleClose, message }) => (
  <Dialog
    open={open}
    TransitionComponent={Transition}
    keepMounted
    onClose={handleClose}
    aria-describedby="alert-dialog-slide-description"
    fullWidth
  >
    <DialogTitle>{"Notification"}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-slide-description">
        {message}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose}>Close</Button>
    </DialogActions>
  </Dialog>
);

const Interviewer = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [interviewerList, setInterviewerList] = useState([]);  
  const [interviewerData, setInterviewerData] = useState({   
    interviewerName: "",
    interviewerJobTitle: "",
  });
  const [errors, setErrors] = useState({
    interviewerName: "",
    interviewerJobTitle: "",
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState(""); // Search query
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleNotificationClose = () => {
    setOpenNotification(false);
    setTimeout(() => {
      window.location.reload();  
    }, 500); 
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };


  


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInterviewerData({ ...interviewerData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const validateForm = () => {
    let isValid = true;
  
    // Initialize errors for all fields
    const newErrors = { username: "", fullName: "", interviewerJobTitle: "", password: "" };
  
    // Validate Username
    if (!interviewerData.username) {
      newErrors.username = "Username is required.";
      isValid = false;
    }
  
    // Validate Full Name
    if (!interviewerData.fullName) {
      newErrors.fullName = "Full Name is required.";
      isValid = false;
    }
  
    // Validate Interviewer Job Title
    if (!interviewerData.interviewerJobTitle) {
      newErrors.interviewerJobTitle = "Interviewer Job Title is required.";
      isValid = false;
    }
  
    // Validate Password
    if (!interviewerData.password) {
      newErrors.password = "Password is required.";
      isValid = false;
    }
  
    // Set errors state
    setErrors(newErrors);
    
    return isValid;
  };
  

  const handleAddInterviewer = async () => {
    if (!validateForm()) {
      return;
    }

    setLoading(true);

    try {
      const birdviewApiUrl = process.env.REACT_APP_BIRDVIEW_API_URL;
      const response = await fetch(`${birdviewApiUrl}/api/interview/addInterviewer`, {
        method: "POST",
        headers: { 
          "Content-Type": "application/json",
        },
        body: JSON.stringify(interviewerData),
      });

      if (response.ok) {
         
        setNotificationMessage("Interviewer added successfully.");
        setInterviewerList([...interviewerList, interviewerData]);
      }
       else {
        setNotificationMessage("Failed to add interviewer.");
      }
    } catch (error) {
      // console.error("Error:", error);
      setNotificationMessage(`Error occurred while adding interviewer: ${error.message}`);
    } finally {
      setLoading(false);
      setOpenDialog(false);
      setOpenNotification(true);
    }
  };

  const fetchInterviewers = useCallback(async () => {
    try {
      const birdviewApiUrl = process.env.REACT_APP_BIRDVIEW_API_URL;

      const response = await fetch(`${birdviewApiUrl}/api/interview/getAllInterviewers`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }

      const data = await response.json();
      setInterviewerList(data);  // Update the list of interviewers
      // console.log('Fetched data:', data);
    } catch (error) {
      // console.error('Error fetching interviewers:', error.message);
      setNotificationMessage(`Error fetching interviewers: ${error.message}`);
      setOpenNotification(true);
    }
  }, []);

  useEffect(() => {
    fetchInterviewers();
  }, [fetchInterviewers]);

  const handleStatusChange = async (interviewerID, newStatus) => {
    // Send the updated status to the backend
    try {
      const birdviewApiUrl = process.env.REACT_APP_BIRDVIEW_API_URL;
      const response = await fetch(`${birdviewApiUrl}/api/interview/updateInterviewerStatus`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ interviewerID, newStatus }),
      });
  
      if (response.ok) {
        // Set notification message
        setNotificationMessage('Status updated successfully.');
        setOpenNotification(true); // Open notification
  
        // Reload the page after 2 seconds
        setTimeout(() => {
          window.location.reload();
        }, 2000); // 2000 milliseconds = 2 seconds
      } else {
        throw new Error('Failed to update status');
      }
    } catch (error) {
      // console.error('Error:', error);
      // Set notification message for error
      setNotificationMessage('Failed to update status.');
      setOpenNotification(true); // Open notification on error
    }
  };
  
// Filter interviewers based on search query and selected role
const filteredInterviewers = interviewerList.filter((interviewer) => {
  const matchesSearch = interviewer.fullName.trim().toLowerCase().includes(searchQuery.trim().toLowerCase());

  return matchesSearch  ;
});

  return (
    <div>
          <div className=" text-lg font-semibold flex border-b border-gray-300  justify-center items-center p-1">
          <h1>Interviewers Panel</h1>
          </div>
      
         
         {/* Search and Role Filter */}
         <div className="flex justify-between ">
        <FormControl variant="outlined" size="small" margin="dense" style={{ minWidth: 120 }}>
        <TextField
          label="Search by Name"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={handleSearchChange}
        />
        </FormControl>

      
        <div className="flex justify-end p-2">
        <Button style={{ backgroundColor: '#157EBC' }} variant="contained" startIcon={<PersonAddIcon />} onClick={handleClickOpen}>
          Add
        </Button>
       </div>
      </div>
      <div className=" ">
      
      <div className=" ">
        <table className="min-w-full border-collapse border border-gray-300">
          {/* Table Head */}
          <thead>
            <tr style={{ backgroundColor: '#157EBC' }} className="  text-white">
              <th className="border border-gray-300 px-4 py-2">Interviewer ID</th>
              <th className="border border-gray-300 px-4 py-2">Interviewer Name</th>
              <th className="border border-gray-300 px-4 py-2">Role</th>
              <th className="border border-gray-300 px-4 py-2">Status</th>
              <th className="border border-gray-300 px-4 py-2">Action</th>
            </tr> 
          </thead>

          {/* Table Body */}
          <tbody>
          {filteredInterviewers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((interviewer) => (
            <tr key={interviewer.id}>
                <td className="border border-gray-300 px-4 py-2">{interviewer.id}</td>
                <td className="border border-gray-300 px-4 py-2">{interviewer.fullName}</td>
                <td className="border border-gray-300 px-4 py-2">{interviewer.interviewerJobTitle}</td>
                <td className="border border-gray-300 px-4 py-2">{interviewer.status}</td>
                <td className="border border-gray-300 px-4 py-2 text-center">
                {interviewer.status === 'active' ? (
                    <ToggleOnIcon
                    style={{ cursor: 'pointer', color: 'green' }} // Style the icon
                    onClick={() => handleStatusChange(interviewer.id, 'inactive')}
                    />
                ) : (
                    <ToggleOffIcon
                    style={{ cursor: 'pointer', color: '#E42D2C' }} // Style the icon
                    onClick={() => handleStatusChange(interviewer.id, 'active')}
                    />
                )}
                </td>
            </tr>
            ))}
        </tbody>

        </table>
      </div>

      <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredInterviewers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>

   {/* Dialog for Adding New Interviewer */}
   <Dialog open={openDialog} fullWidth onClose={handleClose}>
  <DialogTitle>Add New Interviewer</DialogTitle>
  <DialogContent>
    {/* Username Field */}
    <TextField
      margin="dense"
      label="Username"
      type="text"
      fullWidth
      name="username"
      value={interviewerData.username}
      onChange={handleInputChange}
      error={Boolean(errors.username)}
      helperText={errors.username}
    />

    {/* Full Name Field */}
    <TextField
      margin="dense"
      label="Full Name"
      type="text"
      fullWidth
      name="fullName"
      value={interviewerData.fullName}
      onChange={handleInputChange}
      error={Boolean(errors.fullName)}
      helperText={errors.fullName}
    />

    {/* Interviewer Job Title Field */}
    <TextField
      margin="dense"
      label="Interviewer Title"
      type="text"
      fullWidth
      name="interviewerJobTitle"
      value={interviewerData.interviewerJobTitle}
      onChange={handleInputChange}
      error={Boolean(errors.interviewerJobTitle)}
      helperText={errors.interviewerJobTitle}
    />

    {/* Password Field */}
    <TextField
      margin="dense"
      label="Password"
      type="password"
      fullWidth
      name="password"
      value={interviewerData.password}
      onChange={handleInputChange}
      error={Boolean(errors.password)}
      helperText={errors.password}
    />
  </DialogContent>
  <DialogActions>
    <Button onClick={handleClose} color="secondary">
      Cancel
    </Button>
    <Button onClick={handleAddInterviewer} color="primary" disabled={loading}>
      {loading ? <CircularProgress size={24} /> : "Add"}
    </Button>
  </DialogActions>
</Dialog>


      {/* Notification Dialog */}
      <AlertDialogSlide
        open={openNotification}
        handleClose={handleNotificationClose}
        message={notificationMessage}
      />
    </div>  
  );
};

export default Interviewer;
