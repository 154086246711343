import React, { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [alertmsg, setAlertmsg] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const birdviewApiUrl = process.env.REACT_APP_BIRDVIEW_API_URL;
      const response = await axios.post(
        `${birdviewApiUrl}/api/auth/login`, 
        { username, password }, 
        { headers: { 'Content-Type': 'application/json' } }
      );
      
      setLoading(false);
      setAlertmsg(true);
      setMessage(response.data.message);
  
      localStorage.setItem('token', response.data.token);

      // Redirect to interview.js
      navigate('/interview'); // Adjust the path as needed

    } catch (error) {
      console.error('Error during login:', error);
      setLoading(false);
      handleAxiosError(error);
    }
  };

  const handleAxiosError = (error) => {
    if (error.response) {
      setMessage('Invalid username or password');
    } else if (error.request) {
      setMessage('Network error, please try again later');
    } else {
      setMessage('An unexpected error occurred');
    }
    setAlertmsg(true);
  };

   // Function to decode the token and set state
    useEffect(() => {
      const token = localStorage.getItem('token');
      if (!token) {
          
      }else if (token) {
  
       navigate('/interview'); // Adjust the path as needed
      }
    }, [navigate]);  

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center">
      <div className="bg-white p-8 rounded shadow-lg w-96">
        <h2 className="text-2xl font-bold text-center mb-6 text-blue-600">Login</h2>
        {loading && <p className="text-blue-600 text-center">Loading...</p>}
        {alertmsg && <p className="text-red-600 text-center">{message}</p>}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <TextField
              label="Username"
              variant="outlined"
              fullWidth
              margin="normal"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="mb-6">
            <TextField
              label="Password"
              type="password"
              variant="outlined"
              fullWidth
              margin="normal"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button type="submit" className="bg-blue-600 text-white py-2 px-4 w-full rounded hover:bg-blue-700">
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
