import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";

const CreateJobInterviewDialog = ({ open, handleClose, handleSaveCreateJobInterview, departmentId, department }) => {
  const [interviewDetails, setInterviewDetails] = useState({
    departmentId: parseInt(departmentId),
    interviewJobTitle: '',
    date: '',
    time: '',
  });

  const [errors, setErrors] = useState({});

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInterviewDetails({
      ...interviewDetails,
      [name]: value,
    });

    // Validate on change
    if (value.trim() === '') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: 'This field is required',
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: '',
      }));
    }
  };

  const handleSave = () => {
    // Additional validation before saving
    const newErrors = {};
    if (!interviewDetails.interviewJobTitle.trim()) {
      newErrors.interviewJobTitle = 'Interview Job Title is required';
    }
    if (!interviewDetails.date) {
      newErrors.date = 'Date is required';
    }
    if (!interviewDetails.time) {
      newErrors.time = 'Time is required';
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    handleSaveCreateJobInterview(interviewDetails);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        Create Job Interview <span style={{ color: '#E42D2C' }}>{department}</span>
      </DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Department Id"
          name="departmentId"
          value={departmentId}
          type="number"
          disabled
          fullWidth
        />
        <TextField
          margin="dense"
          label="Interview Job Title"
          name="interviewJobTitle"
          value={interviewDetails.interviewJobTitle}
          onChange={handleChange}
          fullWidth
          error={!!errors.interviewJobTitle}
          helperText={errors.interviewJobTitle}
        />
        <TextField
          margin="dense"
          label="Date"
          type="date"
          name="date"
          value={interviewDetails.date}
          onChange={handleChange}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors.date}
          helperText={errors.date}
        />
        <TextField
          margin="dense"
          label="Time"
          type="time"
          name="time"
          value={interviewDetails.time}
          onChange={handleChange}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors.time}
          helperText={errors.time}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          color="primary"
          disabled={
            !interviewDetails.interviewJobTitle.trim() ||
            !interviewDetails.date ||
            !interviewDetails.time
          }
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateJobInterviewDialog;
