import React, { useState, useCallback, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  CircularProgress,
  TablePagination,
} from "@mui/material";
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import Slide from "@mui/material/Slide";
import DialogContentText from "@mui/material/DialogContentText";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AlertDialogSlide = ({ open, handleClose, message }) => (
  <Dialog
    open={open}
    TransitionComponent={Transition}
    keepMounted
    onClose={handleClose}
    aria-describedby="alert-dialog-slide-description"
    fullWidth
  >
    <DialogTitle>{"Notification"}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-slide-description">
        {message}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose}>Close</Button>
    </DialogActions>
  </Dialog>
);

const AddInterviewee = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [intervieweeList, setIntervieweeList] = useState([]);  // This is the list of interviewees
  const [intervieweeData, setIntervieweeData] = useState({  // This is the form data
    intervieweeName: "",
    intervieweeJobTitle: "",
  });
  const [errors, setErrors] = useState({
    intervieweeName: "",
    intervieweeJobTitle: "",
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState(""); // Search query
  const [selectedRole, setSelectedRole] = useState(""); // Role filter
  const [interviewJobTitles, setInterviewJobTitles] = useState([]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleNotificationClose = () => {
    setOpenNotification(false);
    setTimeout(() => {
      window.location.reload();  
    }, 500); 

  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };


  const handleRoleChange = (e) => {
    setSelectedRole(e.target.value);
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;


    setIntervieweeData({ ...intervieweeData, [name]: value });


    setErrors({ ...errors, [name]: "" });


  };
 
  const handleInputChangeInterviewerRole = (e) => {
    const interviewJobTitleID = e.target.value; // Correctly extract value from the event
  
    const interviewJobTitle = interviewJobTitles.find(
      (jobTitle) => jobTitle.id === parseInt(interviewJobTitleID)
    );
  
    // Update both intervieweeJobTitle and intervieweeJobTitleID in a single state update
    setIntervieweeData((prevData) => ({
      ...prevData,
      intervieweeJobTitle: interviewJobTitle?.interviewJobTitle || "", // Fix typo: interviewJobTitletitle -> interviewJobTitle
      intervieweeJobTitleID: interviewJobTitleID,
    }));
  
    setErrors({ ...errors, intervieweeJobTitle: "" }); // Clear errors
  };
  




  const validateForm = () => {
    let isValid = true;
    const newErrors = { intervieweeName: "", intervieweeJobTitle: "" };

    if (!intervieweeData.intervieweeName) {
      newErrors.intervieweeName = "Interviewee name is required.";
      isValid = false;
    }

    if (!intervieweeData.intervieweeJobTitle) {
      newErrors.intervieweeJobTitle = "Role is required.";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleAddInterviewee = async () => {
    if (!validateForm()) {
      return;
    }

    setLoading(true);

    try {
      const birdviewApiUrl = process.env.REACT_APP_BIRDVIEW_API_URL;

      const response = await fetch(`${birdviewApiUrl}/api/interview/addInterviewee`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(intervieweeData),
      });

      if (response.ok) {
        // const result = await response.json();
        // console.log("Interviewee added successfully:", result);
        setNotificationMessage("Interviewee added successfully.");
        setIntervieweeList([...intervieweeList, intervieweeData]);
       
      }
       else {
        setNotificationMessage("Failed to add interviewee.");
      }
    } catch (error) {
      // console.error("Error:", error);
      setNotificationMessage(`Error occurred while adding interviewee: ${error.message}`);
    } finally {
      setLoading(false);
      setOpenDialog(false);
      setOpenNotification(true);
    }
  };

  const fetchInterviewees = useCallback(async () => {
    try {
      const birdviewApiUrl = process.env.REACT_APP_BIRDVIEW_API_URL;

      const response = await fetch(`${birdviewApiUrl}/api/interview/getAllInterviewees`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }

      const data = await response.json();
      setIntervieweeList(data);  // Update the list of interviewees
      // console.log('Fetched data:', data);
    } catch (error) {
      // console.error('Error fetching interviewees:', error.message);
      setNotificationMessage(`Error fetching interviewees: ${error.message}`);
      setOpenNotification(true);
    }
  }, []);

  useEffect(() => {
    fetchInterviewees();
  }, [fetchInterviewees]);

  useEffect(() => {
    const birdviewApiUrl = process.env.REACT_APP_BIRDVIEW_API_URL;
    fetch(`${birdviewApiUrl}/api/interview/getAllInterviewJobTitlesInformation`)
      .then((response) => response.json())
      .then((data) => {
        setInterviewJobTitles(data);
      })
      .catch((error) => {
        // console.error("Error fetching job titles:", error);
      });
  }, [setInterviewJobTitles]);

  const handleStatusChange = async (intervieweeID, newStatus) => {
    // Send the updated status to the backend
    try {
      const birdviewApiUrl = process.env.REACT_APP_BIRDVIEW_API_URL;
      const response = await fetch(`${birdviewApiUrl}/api/interview/updateStatus`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ intervieweeID, newStatus }),
      });
  
      if (response.ok) {
        // Set notification message
        setNotificationMessage('Status updated successfully.');
        setOpenNotification(true); // Open notification
  
        // Reload the page after 2 seconds
        setTimeout(() => {
          window.location.reload();
        }, 2000); // 2000 milliseconds = 2 seconds
      } else {
        throw new Error('Failed to update status');
      }
    } catch (error) {
      // console.error('Error:', error);
      // Set notification message for error
      setNotificationMessage('Failed to update status.');
      setOpenNotification(true); // Open notification on error
    }
  };
  
// Filter interviewees based on search query and selected role
const filteredInterviewees = intervieweeList.filter((interviewee) => {
  const matchesSearch = interviewee.intervieweeName.trim().toLowerCase().includes(searchQuery.trim().toLowerCase());
  const matchesRole = selectedRole ? interviewee.intervieweeJobTitle === selectedRole : true;
  return matchesSearch && matchesRole;
});

  return (
    <div>
          <div className=" text-lg font-semibold flex border-b border-gray-300  justify-center items-center p-1">
          <h1>Interviewees Details</h1>
          </div>
    
         {/* Search and Role Filter */}
         <div className="flex justify-between ">
         <FormControl variant="outlined" size="small" margin="dense" style={{ minWidth: 120 }}>
        <TextField
          label="Search by Name"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={handleSearchChange}
        />
        </FormControl>

        <FormControl variant="outlined" size="small" margin="dense" style={{ minWidth: 200 }}>
          <InputLabel id="role-label">Role</InputLabel>
          <Select
            labelId="role-label"
            id="role"
            value={selectedRole}
            onChange={handleRoleChange}
            label="Role"
          >
            <MenuItem value="">All Roles</MenuItem>
            {interviewJobTitles.map((interview) => (
            <MenuItem key={interview.id} value={interview.interviewJobTitle}>
             {interview.interviewJobTitle}
            </MenuItem>
          ))}
          </Select>
        </FormControl>
        <div className="flex justify-end p-2">
        
        <Button style={{ backgroundColor: '#157EBC' }} variant="contained" startIcon={<PersonAddIcon />} onClick={handleClickOpen}>
          Add
        </Button>
      </div> 
      </div>
      <div className=" ">
      
      <div className=" ">
        <table className="min-w-full border-collapse border border-gray-300">
          {/* Table Head */}
          <thead>
            <tr style={{ backgroundColor: '#157EBC' }} className="  text-white">
              <th className="border border-gray-300 px-4 py-2">Interviewee ID</th>
              <th className="border border-gray-300 px-4 py-2">Interviewee Name</th>
              <th className="border border-gray-300 px-4 py-2">Role</th>
              <th className="border border-gray-300 px-4 py-2">Status</th>
              <th className="border border-gray-300 px-4 py-2">Action</th>
            </tr>
          </thead>

          {/* Table Body */}
          <tbody>
            {filteredInterviewees.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((interviewee) => (
              <tr key={interviewee.intervieweeID}>
                <td className="border border-gray-300 px-4 py-2">{interviewee.intervieweeID}</td>
                <td className="border border-gray-300 px-4 py-2">{interviewee.intervieweeName}</td>
                <td className="border border-gray-300 px-4 py-2">{interviewee.intervieweeJobTitle}</td>
                <td className="border border-gray-300 px-4 py-2">{interviewee.status}</td>
                <td className="border border-gray-300 px-4 py-2 text-center">
                  {interviewee.status === 'completed' ? (
                    <ToggleOnIcon
                      style={{ color: 'green', cursor: 'pointer' }}
                      onClick={() => handleStatusChange(interviewee.intervieweeID, 'pending')}
                    />
                  ) : (
                    <ToggleOffIcon
                      style={{ color: '#E42D2C', cursor: 'pointer' }}
                      onClick={() => handleStatusChange(interviewee.intervieweeID, 'completed')}
                    />
                  )}
                </td>
              </tr>
            ))}
        </tbody>

        </table>
      </div>

      <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredInterviewees.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>

      {/* Dialog for Adding New Interviewee */}
      <Dialog open={openDialog} fullWidth onClose={handleClose}>
        <DialogTitle>Add New Interviewee</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Interviewee Name"
            type="text"
            fullWidth
            name="intervieweeName"
            value={intervieweeData.intervieweeName}
            onChange={handleInputChange}
            error={Boolean(errors.intervieweeName)}
            helperText={errors.intervieweeName}
          />
          <FormControl fullWidth variant="outlined" margin="dense" error={!!errors.intervieweeJobTitle}>
            <InputLabel id="role-label">Role</InputLabel>
            <Select
              labelId="role-label"
              id="role"
              name="intervieweeJobTitle"
              value={intervieweeData.intervieweeJobTitleID || ""} // Ensure the correct state field is used
              onChange={handleInputChangeInterviewerRole} // Use the handler correctly
              label="Role"
            >
              <MenuItem value="" disabled>
                Select Role
              </MenuItem>
              {interviewJobTitles.map((interview) => (
                <MenuItem key={interview.id} value={interview.id}>
                  {interview.interviewJobTitle}
                </MenuItem>
              ))}
            </Select>
            {errors.intervieweeJobTitle && (
              <FormHelperText>{errors.intervieweeJobTitle}</FormHelperText>
            )}
          </FormControl>

          
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleAddInterviewee} color="primary" disabled={loading}>
            {loading ? <CircularProgress size={24} /> : "Add"}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Notification Dialog */}
      <AlertDialogSlide
        open={openNotification}
        handleClose={handleNotificationClose}
        message={notificationMessage}
      />
    </div>
  );
};

export default AddInterviewee;
