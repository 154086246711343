import React  from 'react';
import InterpreterModeIcon from '@mui/icons-material/InterpreterMode';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import { TextField, Button  } from '@mui/material';

const InterviewFormSession =  ( { 
    intervieweeName,
    tokenUsername,
    intervieweeJobTitle,
    formData,
    handleInputChange,
    errorsGeneralProducts,
    setErrorsGeneralProducts,
    capitalizeWords,
    isAssignedTask,
    skillHandlers,
    handleValidationForm
 } ) =>{

    return(
       <>
            <div style={{backgroundColor:'#E42D2C'}} className='p- rounded shadow-lg ' >

            <h5   className="text-lg font-bold text-white text-center mb-3">Interviewee Details  </h5>
            <div> 

            <div  style={{backgroundColor:'#157EBC'}}    className='flex justify-between items-center p-2  text-white border-b border-gray-300 bg-gray-300 shadow-md'>
            <div className='flex flex-col '>
                <span className="text-lg font-medium  "><InterpreterModeIcon/> Interviewee: <span  >{intervieweeName }</span> </span>
                <span className="text-lg font-medium  "><AdminPanelSettingsIcon /> Interviewer: <span   >{tokenUsername}</span> </span>
            </div>
            <div className='flex justify-end'>
                <span className="text-lg font-medium  "><WorkspacesIcon/> Position: <span  >{intervieweeJobTitle }</span></span>
            </div>
            </div>
            <div className="bg-white">

                {/* Academic Section */}
                <section className="bg-gray-50  rounded shadow-md mb-2 shadow-div">
                    <div className='bg-gray-200 p-4 mb-2'>
                    <h5 className="text-xl font-semibold text-gray-800  ">Academic</h5>
                    </div>
                    <div className="flex items-center mb-4">
                    <div className="flex-1 font-medium text-gray-700">KCSE Certificate</div>
                    <div className="flex-1">
                    <TextField
                        name="kcseCertificateRating"
                        value={formData.kcseCertificateRating}
                        onChange={(e) => {
                        handleInputChange(e);
                        if (errorsGeneralProducts.kcseCertificateRating) {
                            setErrorsGeneralProducts(prevErrors => ({
                            ...prevErrors,
                            kcseCertificateRating: ''
                            }));
                        }
                        }}
                        type="number"
                        inputProps={{ min: 1, max: 5 }}
                        label="Rating (1-5)"
                        error={Boolean(errorsGeneralProducts.kcseCertificateRating)}
                        // Combine the caption with the error message
                        helperText={
                        errorsGeneralProducts.kcseCertificateRating
                            ?  errorsGeneralProducts.kcseCertificateRating 
                            : '1. D 2. D+ 3. Cs 4. Bs 5.As'
                        }
                        fullWidth 
                    />
                    </div>
                    </div>
                    <div className="flex items-center mb-4">
                    <div className="flex-1 font-medium text-gray-700">Post Secondary Qualifications</div>
                    <div className="flex-1">
                        <TextField
                            name="postSecondaryRating"
                            value={formData.postSecondaryRating}
                            onChange={(e) => {
                            handleInputChange(e);
                            // Clear the error when the user starts typing
                            if (errorsGeneralProducts.postSecondaryRating) {
                                setErrorsGeneralProducts(prevErrors => ({
                                ...prevErrors,
                                postSecondaryRating: ''
                                }));
                            }
                            }}
                            type="number"
                            inputProps={{ min: 1, max: 5 }}
                            label="Rating (1-5)"
                            error={Boolean(errorsGeneralProducts.postSecondaryRating)}
                            // Display the error or a helpful message
                            helperText={
                            errorsGeneralProducts.postSecondaryRating
                                ? errorsGeneralProducts.postSecondaryRating
                                : '1.Any Cert 2.relevant Cert 3.Diploma 4.Any degree 5.Relevant Degree'
                            }
                            fullWidth 

                        />
                    </div>
                    </div>
                </section>

                {/* Work-Skills Section */}
                <section className="bg-gray-50 p-4 rounded shadow-md mb-2 shadow-div">

                    <div className='bg-gray-200 p-4 mb-2'>
                    <h5 className="text-xl font-semibold text-gray-800  ">Work-Skills</h5>
                    </div>

                    <div className="flex items-center mb-4">
                    <div className="flex-1 font-medium text-gray-700">Relevant Work Experience</div>
                    <div className="flex-1">
                        <TextField
                        fullWidth
                        name="workExperienceRating"
                        value={formData.workExperienceRating}
                        onChange={(e) => {
                            handleInputChange(e);
                            if (errorsGeneralProducts.workExperienceRating) {
                            setErrorsGeneralProducts(prevErrors => ({
                                ...prevErrors,
                                workExperienceRating: ''
                            }));
                            }
                        }}
                        type="number"
                        inputProps={{ min: 1, max: 5 }}
                        label="Rating (1-5)"
                        error={Boolean(errorsGeneralProducts.workExperienceRating)}
                        helperText={errorsGeneralProducts.workExperienceRating ? errorsGeneralProducts.workExperienceRating
                                : '1.(0-6months) 2.(6months) 3.( 1yr ) 4.(6months-1yr) 5.(over 1yr relevant)'
                            }
                        />

                    </div>
                    </div>
                </section>
                {/* Technical Skills Section */}
                <section className="bg-gray-50 p-4 rounded shadow-md mb-2 shadow-div">

                    <div className='bg-gray-200 p-4 mb-2'>
                    <h5 className="text-xl font-semibold text-gray-800  ">Technical Skills</h5>
                    </div>
                    <div className="flex items-center mb-4">
                    <div className="flex-1 font-medium text-gray-700">Computer Knowledge</div>
                    <div className="flex-1">
                        <TextField
                        fullWidth
                        name="technicalSkillsRating"
                        value={formData.technicalSkillsRating}
                        onChange={(e) => {
                            handleInputChange(e);
                            if (errorsGeneralProducts.technicalSkillsRating) {
                            setErrorsGeneralProducts(prevErrors => ({
                                ...prevErrors,
                                technicalSkillsRating: ''
                            }));
                            }
                        }}
                        type="number"
                        inputProps={{ min: 1, max: 5 }}
                        label="Rating (1-5)"
                        error={Boolean(errorsGeneralProducts.technicalSkillsRating)}
                        helperText={errorsGeneralProducts.technicalSkillsRating ?  errorsGeneralProducts.technicalSkillsRating 
                            : '1. No computer knowledge 2. computer Knowlegde 3. Cert 4. Diploma 5. degree'
                        }
                    />
                    </div>
                    </div>
                </section>

                <section className="bg-gray-50 p-4 rounded shadow-md mb-2 shadow-div">
                    <div className='bg-gray-200 p-4 mb-2'>
                    <h5 className="text-xl font-semibold text-gray-800  ">Soft Skills</h5>
                    </div>

                {['communicationRating', 'attitudeRating', 'imageEtiquetteRating', 'subjectMatterKnowledgeRating', 'companyProductKnowledgeRating', 'problemSolvingRating', 'selfAwarenessRating', 'careerAspirationsRating'].map((skill, index) => (
                    <div key={index} className="flex items-center mb-4">
                        <div className="flex-1 font-medium text-gray-700">
                            {capitalizeWords(skill.replace(/Rating/g, '').replace(/([A-Z])/g, ' $1'))} 
                            <span className="text-gray-500">({skillHandlers[skill]})</span> {/* Display handler */}
                        </div>
                        <div className="flex-1">
                            <TextField
                                fullWidth
                                name={skill}
                                value={formData[skill]}
                                onChange={(e) => {
                                    handleInputChange(e);
                                    if (errorsGeneralProducts[skill]) {
                                        setErrorsGeneralProducts(prevErrors => ({
                                            ...prevErrors,
                                            [skill]: ''
                                        }));
                                    }
                                }}
                                type="number"
                                inputProps={{ min: 1, max: 5 }}
                                label="Rating (1-5)"
                                error={Boolean(errorsGeneralProducts[skill])}
                                helperText={errorsGeneralProducts[skill]}
                            />
                        </div>
                    </div>
                ))}

                    <div className="flex items-center mb-4">
                        <div className="flex-1 font-medium text-gray-700">Salary Expectation</div>
                        <div className="flex-1">
                            <TextField
                                fullWidth
                                name="salaryExpectationRating"
                                value={formData.salaryExpectationRating}
                                onChange={(e) => {
                                    handleInputChange(e);
                                    if (errorsGeneralProducts.salaryExpectationRating) {
                                        setErrorsGeneralProducts(prevErrors => ({
                                            ...prevErrors,
                                            salaryExpectationRating: ''
                                        }));
                                    }
                                }}
                                type="text"
                                disabled={!isAssignedTask}
                                label="Salary Expectation"
                                error={Boolean(errorsGeneralProducts.salaryExpectationRating)}
                                placeholder={isAssignedTask ? "e.g., 20,000-60,0000 0r 66,0000" : "Disabled due to lack of authorization"}
                                helperText={isAssignedTask 
                                    ? errorsGeneralProducts.salaryExpectationRating || 'Please enter e.g. 20,000-60,0000 0r 66,0000, etc.' 
                                    : 'This field is disabled because you are not authorized to modify it.'}
                            />
                        </div>
                    </div>
                    <div className="flex items-center mb-4">
                    <div className="flex-1 font-medium text-gray-700">Availability</div>
                    <div className="flex-1">
                        <TextField
                            fullWidth
                            name="availabilityRating"
                            value={formData.availabilityRating}
                            onChange={(e) => {
                                handleInputChange(e);
                                if (errorsGeneralProducts.availabilityRating) {
                                    setErrorsGeneralProducts(prevErrors => ({
                                        ...prevErrors,
                                        availabilityRating: ''
                                    }));
                                }
                            }}
                            type="text"
                            label="Availability"
                            disabled={!isAssignedTask}
                            placeholder={isAssignedTask ? "e.g., Immediate, 1 month notice" : "Disabled due to lack of authorization"}
                            error={Boolean(errorsGeneralProducts.availabilityRating)}
                            helperText={isAssignedTask 
                                ? errorsGeneralProducts.availabilityRating || 'Please enter Example: "Immediate", "1 month notice", etc.' 
                                : 'This field is disabled because you are not authorized to modify it.'}
                        />
                    </div>
                </div>
                </section>
                </div>

            <div className="flex justify-center mt-8">
            <Button  variant="contained" size="large" onClick={handleValidationForm} sx={{   backgroundColor: 'white',  color: 'black', '&:hover': {   backgroundColor: '#f0f0f0',  } }} > Submit </Button>
            </div>
            </div>
            </div>
       
       </>
    )
}

export default InterviewFormSession;