import React, { useState, useEffect } from "react";
import {  Button, Dialog, DialogActions,  DialogContent,  DialogTitle,  Slide, TextField,  Select,  MenuItem,  InputLabel,  FormControl,  Switch, FormControlLabel,  FormHelperText, Autocomplete } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditInterviewDialog = ({
  open,
  handleClose,
  jobTitle,
  handleSave,
  interviewers ,
}) => {
  const [formData, setFormData] = useState({ ...jobTitle, date: "", time: "" });
  const [errors, setErrors] = useState({});
  const [interviewersAvailable, setInterviewersAvailable] = useState({});
  

  useEffect(() => {
    if (jobTitle) {
      // Set form data based on the selected job title and filter interviewers for additionalInterviewers
      setFormData({
        ...jobTitle,
        additionalInterviewers: interviewers.filter((interviewer) =>
          jobTitle.additionalInterviewersIDs.includes(interviewer.id)
        ),
      });
  
      // Prepare the array of interviewer IDs from the additionalInterviewersIDs
      const allIds = interviewers
        .flatMap((item) =>
          item.additionalInterviewersIDs ? item.additionalInterviewersIDs.split(",") : []
        )
        .map((id) => parseInt(id.trim()))
        .filter((id) => !isNaN(id)); // Filter out NaN values
  
      const uniqueIdsArray = [...new Set(allIds)]; // Get unique IDs
  
      // Filter interviewers that are not part of the unique additional interviewers
      if (Array.isArray(interviewers)) {
        const updatedInterviewers = interviewers.filter(
          (interviewer) => !uniqueIdsArray.includes(interviewer.id)
        );
  
        // Set the updated interviewers array into state
        setInterviewersAvailable(updatedInterviewers);
      }
    }
  }, [jobTitle, interviewers]);
  



  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    const selectedInterviewer = interviewers.find(
      (interviewer) => interviewer.id === value
    );

    setFormData({
      ...formData,
      [name]: value,
      assignedUserFullName: selectedInterviewer?.fullName || "",
    });
  };

  const handleStatusChange = (event) => {
    setFormData({
      ...formData,
      status: event.target.checked ? "yes" : "no",
    });
  };

  const validate = () => {
    let valid = true;
    let newErrors = {};

    // Check if percentages sum to 100
    const totalPercentage =
      (parseFloat(formData.academicPercentage) || 0) +
      (parseFloat(formData.workSkillsPercentage) || 0) +
      (parseFloat(formData.technicalSkillsPercentage) || 0) +
      (parseFloat(formData.softSkillsPercentage) || 0);

    if (totalPercentage !== 100) {
      newErrors.percentageError = "The total percentage must be 100%";
      valid = false;
    }

    // Validate other fields
    if (!formData.academicPercentage || formData.academicPercentage < 0 || formData.academicPercentage > 100) {
      newErrors.academicPercentage = "Academic Percentage is required and should be between 0 and 100.";
      valid = false;
    }

    if (!formData.workSkillsPercentage || formData.workSkillsPercentage < 0 || formData.workSkillsPercentage > 100) {
      newErrors.workSkillsPercentage = "Work Skills Percentage is required and should be between 0 and 100.";
      valid = false;
    }

    if (!formData.technicalSkillsPercentage || formData.technicalSkillsPercentage < 0 || formData.technicalSkillsPercentage > 100) {
      newErrors.technicalSkillsPercentage = "Technical Skills Percentage is required and should be between 0 and 100.";
      valid = false;
    }

    if (!formData.softSkillsPercentage || formData.softSkillsPercentage < 0 || formData.softSkillsPercentage > 100) {
      newErrors.softSkillsPercentage = "Soft Skills Percentage is required and should be between 0 and 100.";
      valid = false;
    }

    if (!formData.assignedUserID) {
      newErrors.assignedUserID = "Assigned Interviewer is required.";
      valid = false;
    }

    if (!formData.date) {
      newErrors.date = "Date is required.";
      valid = false;
    }

    if (!formData.time) {
      newErrors.time = "Time is required.";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = () => {
    if (validate()) {
      const additionalInterviewersNames = Array.isArray(formData.additionalInterviewers)
        ? formData.additionalInterviewers.map((interviewer) => `${interviewer.fullName}(${interviewer.interviewerJobTitle})`).join(",")
        : "";
        
      const additionalInterviewersIDs = Array.isArray(formData.additionalInterviewers)
        ? formData.additionalInterviewers.map((interviewer) => interviewer.id).join(",")
        : "";
  
      // Declare `dataToSave` here
      const dataToSave = {
        ...formData, // spread the formData object
        additionalInterviewersNames: additionalInterviewersNames,
        additionalInterviewersIDs: additionalInterviewersIDs,
      };
  
      handleSave(dataToSave); // Save or submit the transformed data
    }
  };
  
  const handleAutocompleteChange = (event, value) => {
    setFormData({
      ...formData,
      additionalInterviewers: value,
    });
  };

  useEffect(() => {
    const birdviewApiUrl = process.env.REACT_APP_BIRDVIEW_API_URL;
  
    const fetchData = async () => {
      try {
        const response = await fetch(`${birdviewApiUrl}/api/interview/getAllInterviewJobTitlesInformation`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
  
        // Step 1: Extract all interviewer IDs from data
        const allIds = data
          .flatMap(item => item.additionalInterviewersIDs ? item.additionalInterviewersIDs.split(',') : []) // Handle null/undefined
          .map(id => parseInt(id.trim())) // Trim whitespace and parse as integer
          .filter(id => !isNaN(id)); // Ensure valid integers
  
        // Step 2: Remove duplicates using Set
        const uniqueIdsArray = [...new Set(allIds)];
  
        // Step 3: Ensure interviewers is an array
        if (Array.isArray(interviewers)) {
          // Step 4: Remove interviewers that are in uniqueIdsArray from the interviewers list
          const updatedInterviewers = interviewers.filter(
            interviewer => !uniqueIdsArray.includes(interviewer.id)
          );
  
          // Step 5: Set the updated interviewers list in state
          setInterviewersAvailable(updatedInterviewers);
        }
  
      } catch (error) {
        console.error("Error fetching:", error);
      }
    };
  
    fetchData(); // Call the async function
  
    // Dependency array: interviewers and setInterviewersAvailable
  }, [interviewers, setInterviewersAvailable]);
  

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      fullWidth
    >
      <DialogTitle>Edit Interview Job Title</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Interview Job Title"
          type="text"
          fullWidth
          name="interviewJobTitle"
          value={formData.interviewJobTitle || ""}
          onChange={handleChange}
          disabled
        />
        <TextField
          margin="dense"
          label="Academic Percentage"
          type="number"
          fullWidth
          name="academicPercentage"
          value={formData.academicPercentage || ""}
          onChange={handleChange}
          error={!!errors.academicPercentage}
          helperText={errors.academicPercentage}
        />
        <TextField
          margin="dense"
          label="Work Skills Percentage"
          type="number"
          fullWidth
          name="workSkillsPercentage"
          value={formData.workSkillsPercentage || ""}
          onChange={handleChange}
          error={!!errors.workSkillsPercentage}
          helperText={errors.workSkillsPercentage}
        />
        <TextField
          margin="dense"
          label="Technical Skills Percentage"
          type="number"
          fullWidth
          name="technicalSkillsPercentage"
          value={formData.technicalSkillsPercentage || ""}
          onChange={handleChange}
          error={!!errors.technicalSkillsPercentage}
          helperText={errors.technicalSkillsPercentage}
        />
        <TextField
          margin="dense"
          label="Soft Skills Percentage"
          type="number"
          fullWidth
          name="softSkillsPercentage"
          value={formData.softSkillsPercentage || ""}
          onChange={handleChange}
          error={!!errors.softSkillsPercentage}
          helperText={errors.softSkillsPercentage}
        />
        <TextField
          margin="dense"
          label="Date"
          type="date"
          name="date"
          value={formData.date}
          onChange={handleChange}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors.date}
          helperText={errors.date}
        />
        <TextField
          margin="dense"
          label="Time"
          type="time"
          name="time"
          value={formData.time}
          onChange={handleChange}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors.time}
          helperText={errors.time}
        />
        <FormControl fullWidth margin="dense" error={!!errors.assignedUserID}>
          <InputLabel id="select-interviewer-label">Assigned Interviewer</InputLabel>
          <Select
            label="Assigned Interviewer Leader"
            labelId="select-interviewer-label"
            name="assignedUserID"
            value={formData.assignedUserID || ""}
            onChange={handleSelectChange}
          >
            {interviewers.map((interviewer) => (
              <MenuItem key={interviewer.id} value={interviewer.id}>
                {interviewer.fullName} ({interviewer.interviewerJobTitle})
              </MenuItem>
            ))}
          </Select>
          {errors.assignedUserID && (
            <FormHelperText>{errors.assignedUserID}</FormHelperText>
          )}


       <FormControl fullWidth margin="dense" error={!!errors.AdditionalassignedInterviewers}>
       <Autocomplete
          multiple
          options={interviewersAvailable}
          getOptionLabel={(option) => option.fullName || ""}
          value={formData.additionalInterviewers || []}
          onChange={handleAutocompleteChange}
          renderInput={(params) => <TextField {...params} label="Additional Interviewers" />}
        />
        {errors.percentageError && <FormHelperText error>{errors.percentageError}</FormHelperText>}

          {errors.assignedInterviewers && (
            <FormHelperText>{errors.assignedInterviewers}</FormHelperText>
          )}
        </FormControl>


        </FormControl>

        <FormControlLabel
          control={
            <Switch
              checked={formData.status === "yes"}
              onChange={handleStatusChange}
              name="status"
              color="primary"
            />
          }
          label={formData.status === "yes" ? "Active" : "Disabled"}
        />

        {errors.percentageError && (
          <p style={{ color: "red" }}>{errors.percentageError}</p>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSubmit} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditInterviewDialog;
