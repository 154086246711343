import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Login from './auth/Login';
import Interview from './pages/Interview';
import InterviewersResults from './pages/InterviewersResults';
import PersonalResults from './pages/PersonalResults';
import About  from './pages/components/extends/me';


 
const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/interview" element={<Interview />} />
        <Route path="/results" element={<InterviewersResults/>} />
        <Route path="/personalResults" element={<PersonalResults/>} />
        <Route path="/me" element={<About/>} />
        
      </Routes>
    </Router>
  );
};
  
export default App;
