// src/components/InterviewersList.js
import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { CheckCircle } from '@mui/icons-material'; // Import Material-UI icon

const InterviewersList = ({ onLengthChange }) => {
  const [interviewers, setInterviewers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchInterviewers = async () => {
      try {
        const birdviewApiUrl = process.env.REACT_APP_BIRDVIEW_API_URL;
        const response = await fetch(`${birdviewApiUrl}/api/interview/getAllActiveInterviewers`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setInterviewers(data);
        if (onLengthChange) {
          onLengthChange(data.length); // Send length to the parent
        }
      } catch (error) {
        // console.error('Error fetching interviewers:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchInterviewers();
  }, [onLengthChange]);

  return (
    <div className="container mx-auto p-4">
      {loading ? (
        <div className="text-center">Loading...</div>
      ) : (
        <div>
          <div className="text-lg font-semibold flex border-b border-gray-300 mb-2 justify-center items-center p-1">
            <span className='mr-3'>Active Interviewers </span>  <span className="flex items-center justify-center w-6 h-6 text-xs text-white font-bold bg-blue-500 rounded-full">{interviewers.length} </span>
          </div>
          <TableContainer component={Paper} className="shadow-md rounded-lg">
            <Table>
              <TableHead>
                <TableRow className="bg-gray-200">
                  <TableCell className="font-bold">Full Name</TableCell>
                  <TableCell className="font-bold">Status</TableCell>
                  
                </TableRow>
              </TableHead>
              <TableBody>
                {interviewers.map((interviewer) => (
                  <TableRow key={interviewer.id} className="hover:bg-gray-100">
                    <TableCell>{interviewer.fullName}</TableCell>
                    <TableCell>
                      {interviewer.status === 'active' ? (
                        <div className="flex items-center">
                          <CheckCircle className="text-green-500 mr-2" />
                          Active
                        </div>
                      ) : (
                        interviewer.status
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>     
            </Table>
          </TableContainer>
        </div>
      )}
    </div>
  );
};

export default InterviewersList;
