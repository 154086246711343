import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField,  FormControl,  InputLabel, Select,  MenuItem, TablePagination, Table, TableBody, TableCell, TableContainer, TableHead,  TableRow, Paper } from '@mui/material';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOff';
const HrResults = () => {
  const navigate = useNavigate();
  const [interviewees, setInterviewees] = useState([]);
  const [error, setError] = useState(null);
  const [intervieweeIDs, setIntervieweeIDs] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [startTimestamp, setStartTimestamp] = useState('');
  const [endTimestamp, setEndTimestamp] = useState('');
  const [interviewJobTitles, setInterviewJobTitles] = useState([]);

  const getTokenDetails = React.useCallback(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
    }
  }, [navigate]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  const handleStartDateChange = (event) => {
    setStartTimestamp(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndTimestamp(event.target.value);
  };

  useEffect(() => {
    getTokenDetails();
    const storedValues = localStorage.getItem('submittedInterviewees');
    if (storedValues) {
      const parsedValues = JSON.parse(storedValues);
      const uniqueValues = [...new Set(parsedValues)];
      setIntervieweeIDs(uniqueValues);
    }
  }, [getTokenDetails]);

  // Memoized function to fetch interviewees
  const fetchInterviewees = useCallback(async () => {
    try {
      const birdviewApiUrl = process.env.REACT_APP_BIRDVIEW_API_URL;
      const response = await fetch(`${birdviewApiUrl}/api/interview/hrgetallresults`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          intervieweeIDs,
        }),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      // console.log("****TEST******")
      // console.log(data)
  
      // Convert date strings to Date objects
      const startDate = startTimestamp ? new Date(startTimestamp + 'T00:00:00') : null;
      const endDate = endTimestamp ? new Date(endTimestamp + 'T23:59:59') : null;
  
      // Convert interviewee timestamps to Date objects
      const sortedData = data.map(interviewee => ({
        ...interviewee,
        interviewDate: new Date(interviewee.timestamp)
      })).sort((a, b) => b.totalScore - a.totalScore);
  
      // Filter by search query, selected role, and date range
      const filteredData = sortedData.filter(interviewee => {
        const matchesSearchQuery = interviewee.intervieweeNames.some(name =>
          name.trim().toLowerCase().includes(searchQuery.trim().toLowerCase())
        );
        const matchesRole = selectedRole === '' || interviewee.intervieweeJobTitles.some(title =>
          title === selectedRole
        );
        const interviewDate = interviewee.interviewDate;
  
        const matchesDateRange = (!startDate || interviewDate >= startDate ) &&
          (!endDate || interviewDate <= endDate);
  
        return matchesSearchQuery && matchesRole && matchesDateRange;
      });
  
      setTotalCount(filteredData.length);
  
      // Pagination
      const indexOfLastItem = (page + 1) * rowsPerPage;
      const indexOfFirstItem = indexOfLastItem - rowsPerPage;
      const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  
      const rankedData = currentItems.map((interviewee, index) => ({
        ...interviewee,
        rank: indexOfFirstItem + index + 1,
      }));
  
      setInterviewees(rankedData);
    } catch (error) {
      setError(error.message);
    }
  }, [intervieweeIDs, searchQuery, selectedRole, startTimestamp, endTimestamp, page, rowsPerPage]);
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page
  };

  useEffect(() => {
    
      fetchInterviewees();
    
  }, [fetchInterviewees]);
  useEffect(() => {
    const birdviewApiUrl = process.env.REACT_APP_BIRDVIEW_API_URL;
    fetch(`${birdviewApiUrl}/api/interview/getAllInterviewJobTitlesInformation`)
      .then((response) => response.json())
      .then((data) => {
        setInterviewJobTitles(data);
      })
      .catch((error) => {
        // console.error("Error fetching job titles:", error);
      });
  }, [setInterviewJobTitles]);
  return (
    <div>
      <div className="container mx-auto p-4">
        {error ? (
          <p className="text-red-500">Error: {error}</p>
        ) : (
          <div className=" ">
            <div className=" text-lg font-semibold flex border-b border-gray-300  justify-center items-center p-1">
              <h1>Interview Results</h1>
            </div>

            <div className="flex justify-between ">
              <FormControl variant="outlined" size="small" margin="dense" style={{ minWidth: 120 }}>
                <TextField
                  label="Search by Name"
                  variant="outlined"
                  size="small"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </FormControl>

              <FormControl variant="outlined" size="small" margin="dense" style={{ minWidth: 120 }}>
                <InputLabel id="role-label">Role</InputLabel>
                <Select
                  labelId="role-label"
                  id="role"
                  value={selectedRole}
                  onChange={handleRoleChange}
                  label="Role"
                >
                  <MenuItem value="">All Roles</MenuItem>
                  {interviewJobTitles.map((interview) => (
                    <MenuItem key={interview.id} value={interview.interviewJobTitle}>
                    {interview.interviewJobTitle}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl variant="outlined" size="small" margin="dense" style={{ minWidth: 120 }}>
                <TextField
                  label="Start Date"
                  type="date"
                  variant="outlined"
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  value={startTimestamp}
                  onChange={handleStartDateChange}
                />
              </FormControl>

              <FormControl variant="outlined" size="small" margin="dense" style={{ minWidth: 120 }}>
                <TextField
                  label="End Date"
                  type="date"
                  variant="outlined"
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  value={endTimestamp}
                  onChange={handleEndDateChange}
                />
              </FormControl>
            </div>

            <TableContainer component={Paper}>
            <Table>
                <TableHead>
                  <TableRow  >
                    <TableCell>Rank</TableCell>
                    <TableCell>Total Score</TableCell>
                    <TableCell>Names</TableCell>
                    <TableCell>Salary Expectation</TableCell>
                    <TableCell>Availability</TableCell>
                    <TableCell>Role</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Date </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {interviewees.map((interviewee) => (
                    <TableRow key={interviewee.intervieweeID}>
                      <TableCell>{interviewee.rank}</TableCell>
                      <TableCell>
                        {Math.floor((interviewee.totalScore / parseInt(interviewee.interviewersCount, 10)) * 100) / 100}
                      </TableCell>
                      <TableCell onClick={()=>{navigate(`/personalResults?intervieweeID=${interviewee.intervieweeID}&intervieweeNames=${encodeURIComponent(interviewee.intervieweeNames.join(', '))}`)}}>{interviewee.intervieweeNames.join(', ')}</TableCell>
                      <TableCell>{interviewee.salaryExpectationRatings.join(', ')}</TableCell>
                      <TableCell>{interviewee.availabilityRatings.join(', ')}</TableCell>
                      <TableCell>{interviewee.intervieweeJobTitles.join(', ')}</TableCell>
                      <TableCell>
                        {interviewee.status.includes('approve') ? (
                          <>
                            <ToggleOnIcon style={{ cursor: 'pointer', color: 'green' }} />
                            {'approve'}
                          </>
                        ) : (
                          <>
                            <ToggleOffIcon style={{ cursor: 'pointer', color: '#E42D2C' }} />
                            {'decline'}
                          </>
                        )}
                      </TableCell>
                      <TableCell>{interviewee.timestamp.join(', ')}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>

              </Table>
            </TableContainer>

            {/* Pagination Controls */}
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={totalCount} // Use total count here
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default HrResults;
   