import React, { useEffect, useState, useRef, useCallback } from 'react';
import {   Button, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import {jwtDecode} from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import AddInterviewee from './components/AddInterviewee';
import Interviewers from './components/BirdviewInterviewers'; // Placeholder for the Interviewers component
import Settings from './components/Settings'; // Placeholder for the Settings component
import HrResults from './components/HrResults'; // Placeholder for the Results component
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import HomeIcon from '@mui/icons-material/Home';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SettingsIcon from '@mui/icons-material/Settings';
import ChecklistOutlinedIcon from '@mui/icons-material/ChecklistOutlined';
import InterviewFormSession from './components/InterviewFormSession'
import dayjs from "dayjs";
import { Alert } from '@mui/material';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Typography, Box } from '@mui/material';
import EventIcon from '@mui/icons-material/Event';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
 
const InterviewForm = () => {
    const navigate = useNavigate();
    const [tokenUsername, setTokenUsername] = useState('');
    const [tokenUserId, setTokenUserId] = useState('');
    const [showQuestionnaire, setShowQuestionnaire] = useState(true);
    const [interviewees, setInterviewees] = useState([]);
    const intervieweeName = useRef(null);
    const intervieweeJobTitle = useRef(null);
    const [selectedInterviewee, setSelectedInterviewee] = useState('');
    const [isAuthorized, setIsAuthorized] = useState(null);
    const [errorsGeneralProducts, setErrorsGeneralProducts] = useState({});
    const [excludedIDs, setExcludedIDs] = useState([]);
    const [isAssignedTask,setIsAssignedTask] = useState(null);
    const [interviewInformationData , setInterviewInformationData] = useState([]);
    const [activeComponent, setActiveComponent] = useState(
        localStorage.getItem('activeComponent') || 'home'
    );
    const currentUserId = tokenUserId; // Replace this with the current user's ID
    const [interviewData, setInterviewData] = useState([]); // Initialize state correctly
    const [currentDateTime, setCurrentDateTime] = useState(dayjs());
    const [canVote, setCanVote] = useState(false);
    const [upcomingInterviews, setUpcomingInterviews] = useState([]);
    const [jobTitleId, setJobTitleId] = useState(null);
    const [isFilteredIntervieweesEmpty, setIsFilteredIntervieweesEmpty] = useState(false);

    // Save the selected component to localStorage whenever it changes
    
    useEffect(() => {
        localStorage.setItem('activeComponent', activeComponent);
    }, [activeComponent]);

    const renderComponent = () => {
        switch (activeComponent) {
            case 'home':
                return <AddInterviewee />;
            case 'interviewers':
                return <Interviewers />;
            case 'settings':
                return <Settings />;
            case 'hrResults':
                return <HrResults />;
            default:
                return <AddInterviewee />;
        }
    };

    const [formData, setFormData] = useState({
        intervieweeName: '',
        interviewerName: '',
        intervieweeID: '',
        kcseCertificateRating: '',
        postSecondaryRating: '',
        workExperienceRating: '',
        technicalSkillsRating: '',
        communicationRating: '',
        attitudeRating: '',
        imageEtiquetteRating: '',
        subjectMatterKnowledgeRating: '',
        companyProductKnowledgeRating: '',
        problemSolvingRating: '',
        selfAwarenessRating: '',
        careerAspirationsRating: '',
        salaryExpectationRating: '',
        availabilityRating: '',
        isAuthorized: null
    });
 
    
    const getTokenDetails = useCallback(() => {
        const token = localStorage.getItem('token');
        if (token) {
          const decodedToken = jwtDecode(token);
          setTokenUsername(decodedToken['fullName']);
          setTokenUserId(decodedToken['id']);
          setIsAuthorized(decodedToken['authorized'] === 'true');
        } else {
          navigate('/login'); // Adjust the path as needed
        }
      }, [navigate]);

      useEffect(() => {
        const birdviewApiUrl = process.env.REACT_APP_BIRDVIEW_API_URL;
      
        // Fetch the interview job titles data
        fetch(`${birdviewApiUrl}/api/interview/getAllInterviewJobTitlesInformation`)
          .then((response) => response.json())
          .then((data) => {
            setInterviewInformationData(data)
          })
          .catch((error) => {
            // console.error("Error fetching job titles:", error);
          });
      }, [interviewInformationData]); 
    
      const fetchPendingInterviewees = useCallback(async () => {
   
        try {
            const birdviewApiUrl = process.env.REACT_APP_BIRDVIEW_API_URL;
 
            const response = await fetch(`${birdviewApiUrl}/api/interview/getPendingInterviewees`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            
            setInterviewees(data);
        } catch (error) {
            // console.error('Error fetching interviewees:', error);
        }

      }, []);
    
      useEffect(() => {
        getTokenDetails();
        fetchPendingInterviewees();
      }, [getTokenDetails, fetchPendingInterviewees]);

    // Handle interviewee selection
    const handleSelectInterviewee = (e) => {
        const selectedId = e.target.value;
        setSelectedInterviewee(selectedId);
        const interviewee = interviewees.find(ie => ie.intervieweeID === parseInt(selectedId));
        intervieweeName.current = interviewee ? interviewee.intervieweeName : '';
        intervieweeJobTitle.current = interviewee ? interviewee.intervieweeJobTitle : '';



        const token = localStorage.getItem('token');
      
        if (token) {
          const decodedToken = jwtDecode(token);
  
          // Extract the token ID from the decoded token
          const tokenId = decodedToken['id']; 
  
          // Check if any item in data has an ID matching the token ID and job title
          const isAssignedTask = interviewInformationData.some(item => 
            item.assignedUserID === tokenId.toString() && 
            item.interviewJobTitle === intervieweeJobTitle.current
          );
          // Set the state based on the result
          setIsAssignedTask(isAssignedTask);
        } else {
          // Handle case where there is no token
          setIsAssignedTask(false);
        }

        
        setShowQuestionnaire(false);
    };

    // Handle input change
    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    // Validation function
   // Validation function for each step
        const validateStep = () => {
            let tempErrorsGeneralProducts = {};

            // List of rating fields to validate
            const ratingFields = [
                'kcseCertificateRating',
                'postSecondaryRating',
                'workExperienceRating',
                'technicalSkillsRating',
                'communicationRating',
                'attitudeRating',
                'imageEtiquetteRating',
                'subjectMatterKnowledgeRating',
                'companyProductKnowledgeRating',
                'problemSolvingRating',
                'selfAwarenessRating',
                'careerAspirationsRating',
                'salaryExpectationRating',  
                'availabilityRating',      
            ];  
            // Validate each rating field
            ratingFields.forEach(field => {
                if (field === 'salaryExpectationRating'  && isAssignedTask ) {
                    if(!isAssignedTask){
                        return;
                    }else{

                    // Clean the value by removing commas and extra spaces
                    const cleanedValue = formData[field].replace(/,/g, '').trim();
                    
                    if (cleanedValue === '') {
                        tempErrorsGeneralProducts[field] = 'Salary cannot be empty';
                    } else {
                        // Check if cleanedValue contains a range or invalid characters
                        if (!/^[-\d.,]+$/.test(cleanedValue)) {
                            tempErrorsGeneralProducts[field] = 'Invalid characters in salary expectation';
                        } else {
                            // Validate if cleanedValue is a valid number
                            const numberValue = parseFloat(cleanedValue);
                            if (isNaN(numberValue)) {
                                tempErrorsGeneralProducts[field] = 'Salary expectation must be a valid number';
                            }
                        }
                    }  
                }

                } else if (field === 'availabilityRating' && isAssignedTask) {
                    if(!isAssignedTask){
                        return;
                    }else{
                        // Validate other rating fields should be between 1 and 5
                        if (!formData[field] || formData[field].trim() === '') {
                            tempErrorsGeneralProducts[field] = 'Availability cannot be empty';
                     }
                    }   
                } else if (field !== 'availabilityRating' && field !== 'salaryExpectationRating'  )  {
                    // Validate other rating fields should be between 1 and 5
                    if (formData[field] === '' || formData[field] < 1 || formData[field] > 5) {
                        tempErrorsGeneralProducts[field] = 'Rating must be between 1 and 5';
                    }
                }
                    
            });

            // Set errors and return validation status
            setErrorsGeneralProducts(tempErrorsGeneralProducts);

            return Object.keys(tempErrorsGeneralProducts).length === 0;
        };

// Handle form submission
const handleSubmit = async () => {
    setOpenAlert(false);
  
    // Calculate individual sums
    const academicSum = parseInt(formData.kcseCertificateRating || 0, 10) + parseInt(formData.postSecondaryRating || 0, 10);
    const workSkillsSum = parseInt(formData.workExperienceRating || 0, 10);
    const technicalSkillsSum = parseInt(formData.technicalSkillsRating || 0, 10);
    const softSkillsSum = (
        parseInt(formData.communicationRating || 0, 10) +
        parseInt(formData.attitudeRating || 0, 10) +
        parseInt(formData.imageEtiquetteRating || 0, 10) +
        parseInt(formData.subjectMatterKnowledgeRating || 0, 10) +
        parseInt(formData.companyProductKnowledgeRating || 0, 10) +
        parseInt(formData.problemSolvingRating || 0, 10) +
        parseInt(formData.selfAwarenessRating || 0, 10) +
        parseInt(formData.careerAspirationsRating || 0, 10)
    );
  
    // Define default rates
    let academicRate = 0.20; // default rates if no match found
    let workSkillsRate = 0.20;
    let technicalSkillsRate = 0.10;
    let softSkillsRate = 0.50;
  
    // Check interview job title and assign rates accordingly
    const interviewJobTitle = intervieweeJobTitle.current;
    const interviewData = interviewInformationData.find(item => item.interviewJobTitle === interviewJobTitle);
    
    if (interviewData) {
        academicRate = interviewData.academicPercentage / 100;
        workSkillsRate = interviewData.workSkillsPercentage / 100;
        technicalSkillsRate = interviewData.technicalSkillsPercentage / 100;
        softSkillsRate = interviewData.softSkillsPercentage / 100;
    }
  
    // Apply weights to each category
    const weightedTotalScore = 
        (academicSum * academicRate) +
        (workSkillsSum * workSkillsRate) +
        (technicalSkillsSum * technicalSkillsRate) +
        (softSkillsSum * softSkillsRate);
  
    // Construct the formDataWithTotal object
    const formDataWithTotal = {
      kcseCertificateRating:formData.kcseCertificateRating,
      postSecondaryRating:formData.postSecondaryRating,
      workExperienceRating:formData.workExperienceRating,
      technicalSkillsRating:formData.technicalSkillsRating,
      communicationRating:formData.communicationRating,
      attitudeRating:formData.attitudeRating,
      imageEtiquetteRating:formData.imageEtiquetteRating,
      subjectMatterKnowledgeRating:formData.subjectMatterKnowledgeRating,
      companyProductKnowledgeRating:formData.companyProductKnowledgeRating,
      problemSolvingRating:formData.problemSolvingRating,
      selfAwarenessRating:formData.selfAwarenessRating,
      careerAspirationsRating:formData.careerAspirationsRating,
      // interviewee Details
      intervieweeName: intervieweeName.current,
      intervieweeJobTitle:intervieweeJobTitle.current,
      interviewerName: tokenUsername,
      intervieweeID: selectedInterviewee,
      interviewerID: tokenUserId,
      salaryExpectationRating: formData.salaryExpectationRating,
      availabilityRating: formData.availabilityRating,
      isAssignedTask: isAssignedTask,
      totalScore:weightedTotalScore.toFixed(2)
     
    };
  
    try {
        const birdviewApiUrl = process.env.REACT_APP_BIRDVIEW_API_URL;
        const response = await fetch(`${birdviewApiUrl}/api/interview/submit`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formDataWithTotal),
        });
  
        if (response.ok) {
            // const result = await response.json();
            // console.log("Response from backend:", result);
            alert('Form submitted successfully');
  
            // Save the submitted interviewee ID in local storage
            const submittedInterviewees = JSON.parse(localStorage.getItem('submittedInterviewees')) || [];
            submittedInterviewees.push(selectedInterviewee);
            localStorage.setItem('submittedInterviewees', JSON.stringify(submittedInterviewees));
            window.location.reload();
            // Clear the selected interviewee
            setSelectedInterviewee('');
            setShowQuestionnaire(true);
        } else {
            // const error = await response.text();
            // console.error('Error:', error);
            alert('There was an error submitting the form');
        }
    } catch (error) {
        // console.error('Network error:', error);
        alert('Network error. Please try again later.');
    }
  };

      // Helper function to capitalize the first letter of each word
    const capitalizeWords = (text) => {
        return text
            .toLowerCase() // Convert to lowercase first
            .split(' ') // Split into words
            .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
            .join(' '); // Join the words back into a string
    }; 
    
     // tasks ssigned to 
     const skillHandlers = {
        communicationRating: 'Obed',
        attitudeRating: 'Patrick',
        imageEtiquetteRating: 'Mary',
        subjectMatterKnowledgeRating: 'Obed',
        companyProductKnowledgeRating: 'Anida',
        problemSolvingRating: 'Patrick',
        selfAwarenessRating: 'Mary',
        careerAspirationsRating: 'Mercy', 
    };


    useEffect(() => {
        // Retrieve values from local storage
        const storedValues = localStorage.getItem('submittedInterviewees');
        // console.log('Stored values:', storedValues);
    
        let uniqueValues = [];
        if (storedValues) {
          // Parse the JSON string to an array
          const parsedValues = JSON.parse(storedValues);
        //   console.log('Parsed values:', parsedValues);
    
          // Remove duplicates
          uniqueValues = [...new Set(parsedValues)];
        //   console.log('Unique values:', uniqueValues);
    
          // Update state with unique values
          setExcludedIDs(uniqueValues);
        }
      }, []); 
      
       // Filter interviewees based on excluded IDs and matching jobTitleId
      const filteredInterviewees = interviewees.filter(
        interviewee =>
          !excludedIDs.includes(interviewee['intervieweeID']) && 
          interviewee['intervieweeJobTitleID'] === jobTitleId
      );

      useEffect(()=>{
        setIsFilteredIntervieweesEmpty(filteredInterviewees.length === 0);
      },[setIsFilteredIntervieweesEmpty,filteredInterviewees.length])



        // Update button state based on filtered interviewees
      const [openAlert, setOpenAlert] = React.useState(false);

      const handleClickDisagree = () => {
        setOpenAlert(false);
      };
    
      const handleAgree = () => {
          setOpenAlert(true);
          handleSubmit();
      };
      
      const handleValidationForm = () =>{
        // Handle Submission of the form
        if(validateStep()){
            setOpenAlert(true)
        }else{
            // console.log("Please Confirm All Fields")
        }
      }

   
    
      // Fetch interview data from API
      useEffect(() => {
        const fetchInterviewSessionsData = async () => {
          try {
            const birdviewApiUrl = process.env.REACT_APP_BIRDVIEW_API_URL;
            const response = await fetch(`${birdviewApiUrl}/api/interview/fetchInterviewSessionsData`);
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setInterviewData(data); // Set the interview data to state
          } catch (error) {
            console.error('Error fetching interviewers:', error);
          }
        };
    
        fetchInterviewSessionsData();
      }, []); // Empty dependency array ensures this only runs once on component mount
    
      useEffect(() => {
        const checkInterviews = () => {
          const now = dayjs(); // Get the current time using dayjs
          const today = dayjs().format("YYYY-MM-DD"); // Get today's date in YYYY-MM-DD format
          let upcoming = [];
      
          interviewData.forEach((interview) => {
            // Step 1: Store the job title ID from the interview object
            const jobTitleId = interview.id;
      
            // Step 2: Check if the interview date is today
            if (interview.date === today) {
              const interviewTime = dayjs(`${interview.date} ${interview.time}`, "YYYY-MM-DD HH:mm");
      
              // Step 3: Check if the current time is equal to or after the interview time
              if (now.isAfter(interviewTime) || now.isSame(interviewTime)) {
                // Step 4: Split the comma-separated IDs and check if currentUserId is included
                const interviewerIdsArray = interview.additionalInterviewersIDs.split(",");
                if (interviewerIdsArray.includes(currentUserId.toString())) {
                  setCanVote(true); // Set to true if all conditions are met
                  setJobTitleId(jobTitleId)
                 
                }
              }
            }
      
            // Step 5: Collect upcoming interviews
            if (dayjs(interview.date).isAfter(today)) {
              upcoming.push(interview);
            }
          });
      
          setUpcomingInterviews(upcoming); // Store upcoming interviews
        };
      
        checkInterviews(); // Check interviews on component mount
      
        // Update the current time every second and recheck interviews
        const interval = setInterval(() => {
          setCurrentDateTime(dayjs()); // Update the current time
        }, 1000); // 1-second interval to check
      
        return () => clearInterval(interval); // Cleanup the interval on unmount
      }, [interviewData, currentUserId]); // Dependency array
      

  return (
    <div>
      <style>
        {`
          .shadow-div {   background-color: white;   box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.3);
            border-radius: 8px;   padding: 16px;
          }
          .logoImage {    width: 12%;   height: auto;  object-fit: contain;  display: block;   margin: 0;
          }
        `}
      </style>
      <div style={{backgroundColor:'#E42D2C'}} className='p-4'>
     
      </div>
      <div className='shadow-div'>
        <img className='logoImage' src="images/logo.jpeg" alt="Logo" />
        <p className="flex justify-end items-center">
          <span className="text-green-700 font-bold text-lg mr-2">Active</span>
          <span>{tokenUsername}</span>
        </p>

      </div>
 
      <div className="flex justify-center items-center p-4 ">
        <div  className="w-full max-w-6xl    ">
          {showQuestionnaire ? (
            <>   
            <div className='shadow-lg p-2 shadow-div '>
            <h1 className=" text-lg  font-bold   text-center mb-6">Interview Evaluation  </h1>


            <div className=" ">

               
                {(canVote && !isFilteredIntervieweesEmpty) ? (
                  <>
                    {/* Interviewee Selection */}

                    <div className='mb-3'>

                  <h5 className="text-gray-800 mb-4">Select an Interviewee:</h5>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Interviewee</InputLabel>
                    <Select
                      value={selectedInterviewee}
                      onChange={handleSelectInterviewee}
                      label="Interviewee"
                    >
                      {filteredInterviewees.map((interviewee) => (
                        <MenuItem key={interviewee.intervieweeID} value={interviewee.intervieweeID}>
                          {interviewee.intervieweeName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  </div>
                </>
                ) : (
                  <>
                       <div className="flex justify-center">
                          <Button   variant="contained"  style={{ minWidth: 120, backgroundColor: "#157EBC" }}
                            onClick={() => navigate('/results')}   >
                            View Results
                          </Button>
                        </div>
                        
                        <div className="flex justify-center mt-3">
                        <Alert severity="info">
                          Not eligible for Interview Evaluation at this time!
                        </Alert>
                          </div>

                     <div className='flex justify-between m-6 border-t' >
                    {/* No Eligible Interview */}
                    <div className=" flex justify-start ">

                      {/* Upcoming Interviews */}
                      {upcomingInterviews.length > 0 ? (
                        <div>
                          <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                          <Box display="flex" alignItems="center" >
                           
                            <Typography   sx={{ fontWeight: 'bold', color: '#157EBC' }}>
                              Upcoming Interviews
                              <EventIcon   sx={{color:'#157EBC'}} />
                            </Typography>
                          </Box> 
                            <List component="nav" aria-label="main mailbox folders">
                              {upcomingInterviews.map((interview, index) => (
                                <ListItemButton  key={interview.id}  >
                                  <ListItemIcon>
                                    <AccessTimeIcon />
                                  </ListItemIcon>
                                  <ListItemText 
                                    primary={`${interview.interviewJobTitle} on ${interview.date} at ${interview.time}`} 
                                  />
                                </ListItemButton>
                              ))}
                            </List>
                          </Box>

                        </div>
                      ) : (
                        <p>No upcoming interviews.</p>
                      )}

                    </div>
                    <div className=' ' > 
                    <p className="   ">Date: {currentDateTime.format("YYYY-MM-DD")}</p>
                     <p className="  ">Time: {currentDateTime.format("HH:mm:ss")}</p>
                     </div>
                    </div> 

                  </>
                )}
               

              </div>

               {isAuthorized && (
              <div>
              <div style={{ backgroundColor: "#E42D2C" }} className="flex justify-center">
                  <h2 className="text-lg text-white font-semibold p-1">HR Control Panel</h2>
              </div>
              <div style={{ backgroundColor: '#157EBC' }} className="flex items-center justify-center space-x-10 text-white">
                    <Button 
                        onClick={() => setActiveComponent('home')} 
                        startIcon={<HomeIcon style={{ color: 'white' }} />} // Set icon color explicitly
                    >
                        <span className="text-white">
                        Home
                        </span>
                    </Button>

                    <Button 
                        onClick={() => setActiveComponent('interviewers')} 
                        startIcon={<PeopleAltIcon style={{ color: 'white' }} />} // Set icon color explicitly
                    >
                        <span className={`text-white ${activeComponent === 'interviewers' ? 'text-gray-900 bg-transparent' : ''}`}>
                        Interviewers
                        </span>
                    </Button>

                    <Button 
                        onClick={() => setActiveComponent('settings')} 
                        startIcon={<SettingsIcon style={{ color: 'white' }} />} // Set icon color explicitly
                    >
                        <span className={`text-white ${activeComponent === 'settings' ? 'text-gray-900 bg-transparent' : ''}`}>
                        Settings
                        </span>
                    </Button>

                    <Button 
                        onClick={() => setActiveComponent('hrResults')} 
                        startIcon={<ChecklistOutlinedIcon style={{ color: 'white' }} />} // Set icon color explicitly
                    >
                        <span className={`text-white ${activeComponent === 'hrResults' ? 'text-gray-900 bg-transparent' : ''}`}>
                        Results
                        </span>
                    </Button>
                    </div>

              
              {/* Conditionally render the selected component */}
              <div className="p-4">
                  {renderComponent()}
              </div>
               </div>

                )}

              </div>
            </>

          ) : (
            <>  
         <InterviewFormSession
            intervieweeName={intervieweeName.current}
            tokenUsername={tokenUsername}
            intervieweeJobTitle={intervieweeJobTitle.current}
            formData={formData}
            handleInputChange={handleInputChange}
            errorsGeneralProducts={errorsGeneralProducts}
            setErrorsGeneralProducts={setErrorsGeneralProducts}
            capitalizeWords={capitalizeWords} 
            isAssignedTask = {isAssignedTask} 
            skillHandlers = {skillHandlers} 
            handleValidationForm = {handleValidationForm}
        />

            </>
          )}
        </div>
      </div>


      
      <Dialog
        open={openAlert}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClickDisagree}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Confirmation Message"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            ' '
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickDisagree}>Disagree</Button>
          <Button onClick={handleAgree}>Agree</Button>
        </DialogActions>
      </Dialog>


    </div>
  );
};

export default InterviewForm;
