import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@mui/material";
import { useLocation } from "react-router-dom";

const birdviewApiUrl = process.env.REACT_APP_BIRDVIEW_API_URL;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AlertDialogSlide = ({ open, handleClose, message }) => (
  <Dialog
    open={open}
    TransitionComponent={Transition}
    keepMounted
    onClose={handleClose}
    aria-describedby="alert-dialog-slide-description"
    fullWidth
  >
    <DialogTitle>{"Notification"}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-slide-description">
        {message}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose}>Close</Button>
    </DialogActions>
  </Dialog>
);

const PersonalResults = () => {
  const location = useLocation();
  const [openNotification, setOpenNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [results, setResults] = useState([]);

  // Extracting intervieweeID and intervieweeNames from URL query parameters
  const searchParams = new URLSearchParams(location.search);
  const intervieweeID = searchParams.get("intervieweeID");
  const intervieweeNames = searchParams.get("intervieweeNames");

  useEffect(() => {
    const fetchResults = async () => {
      try {
        const response = await fetch(
          `${birdviewApiUrl}/api/interview/getPersonalResults`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              intervieweeID: intervieweeID, // Use the extracted intervieweeID
            }),
          }
        );

        if (response.ok) {
          const data = await response.json();
          setResults(data);
        } else {
          setNotificationMessage("Failed to fetch data");
          setOpenNotification(true);
        }
      } catch (error) {
        setNotificationMessage("An error occurred while fetching data");
        setOpenNotification(true);
      }
    };

    if (intervieweeID) {
      fetchResults();
    } else {
      setNotificationMessage("Invalid interviewee ID");
      setOpenNotification(true);
    }
  }, [intervieweeID]);

  const handleNotificationClose = () => {
    setOpenNotification(false);
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  return (
    <div>
      <style>
        {`
          .shadow-div {
            background-color: white;
            box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.3);
            border-radius: 8px;
            padding: 16px;
          }
          .logoImage {
            width: 12%;
            height: auto;
            object-fit: contain;
            display: block;
            margin: 0;
          }
          .responsive-table {
            overflow-x: auto;
          }
          table {
            width: 100%;
            border-collapse: collapse;
            margin: 16px 0;
          }
          th, td {
            border: 1px solid #dddddd;
            padding: 8px;
            text-align: left;
            white-space: nowrap; /* Prevent text wrapping */
          }
          th {
            background-color: #f2f2f2;
          }
          @media (max-width: 768px) {
            table, th, td {
              font-size: 12px; /* Reduce font size on small screens */
              padding: 4px;
            }
            th, td {
              white-space: normal; /* Allow text wrapping */
            }
          }
        `}
      </style>
      <div style={{ backgroundColor: "#E42D2C" }} className="p-4"></div>
      <div className="shadow-div">
        <img className="logoImage" src="images/logo.jpeg" alt="Logo" />
      </div>

      <div  style={{backgroundColor:'#157EBC'}} className="text-lg text-white font-semibold flex border-b border-gray-300 justify-center items-center p-1">
        <h1>{intervieweeNames} Results</h1>
      </div>

      {/* Notification Dialog */}
      <AlertDialogSlide
        open={openNotification}
        handleClose={handleNotificationClose}
        message={notificationMessage}
      />
  
      {/* Responsive Table */}
      <div className="responsive-table p-3 m-4 shadow-div">
       
        <table>
          <thead  >
            <tr >
              <th  >Interviewer Name</th>
              <th>Job Title</th>
              <th>Total Score</th>
              <th>K.C.S.E.</th>
              <th>Post-Secondary</th>
              <th>Work Experience</th>
              <th>Technical Skills</th>
              <th>Communication</th>
              <th>Attitude</th>
              <th>Image Etiquette</th>
              <th>Subject Matter Knowledge</th>
              <th>Company Product Knowledge</th>
              <th>Problem Solving</th>
              <th>Self Awareness</th>
              <th>Career Aspirations</th>
              <th>Timestamp</th>
            </tr>
          </thead>
          <tbody>
            {results.map((result) => (
              <tr key={result.id}>
                <td>{result.interviewerName}</td>
                <td>{result.intervieweeJobTitle}</td>
                <td>{result.totalScore}</td>
                <td>{result.kcseCertificateRating}</td>
                <td>{result.postSecondaryRating}</td>
                <td>{result.workExperienceRating}</td>
                <td>{result.technicalSkillsRating}</td>
                <td>{result.communicationRating}</td>
                <td>{result.attitudeRating}</td>
                <td>{result.imageEtiquetteRating}</td>
                <td>{result.subjectMatterKnowledgeRating}</td>
                <td>{result.companyProductKnowledgeRating}</td>
                <td>{result.problemSolvingRating}</td>
                <td>{result.selfAwarenessRating}</td>
                <td>{result.careerAspirationsRating}</td>
                <td>{new Date(result.timestamp).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PersonalResults;
