import React from 'react';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const navigate = useNavigate();

  const handleStartClick = () => {
    navigate('/login');
  };

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center">
      <div style={{color:'#157EBC'}} className="text-center">
        
        <h1 style={{color:'#157EBC'}} className="text-4xl font-bold   mb-4">Welcome to Birdview Interview </h1>
        <p className="text-gray-700 mb-8">
          This is your portal to evaluate candidates effectively and efficiently.
        </p>
        <button
          className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700"
          onClick={handleStartClick}
        >
          Start Interview
        </button>
      </div>
    </div>
  );
};

export default Home;
