import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { Button,  Dialog, DialogActions,  DialogContent, DialogTitle,  Slide } from "@mui/material";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import SaveAsIcon from '@mui/icons-material/SaveAs';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import CreateJobInterviewDialog from './extends/CreateJobInterviewDialog'; 

import EditInterviewDialog from './extends/EditInterviewDialog'; 
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AlertDialogSlide = ({ open, handleClose, message }) => (
  <Dialog
    open={open}
    TransitionComponent={Transition}
    keepMounted
    onClose={handleClose}
    aria-describedby="alert-dialog-slide-description"
    fullWidth
  >  
    <DialogTitle>{"Notification"}</DialogTitle>
    <DialogContent>
      <p id="alert-dialog-slide-description">{message}</p>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose}>Close</Button>
    </DialogActions>
  </Dialog>
);




// Add PropTypes for type checking
EditInterviewDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  jobTitle: PropTypes.object.isRequired,
  handleSave: PropTypes.func.isRequired,
  interviewers: PropTypes.array, // Default to empty array if not provided
};

 

const Settings = () => {
  const [openNotification, setOpenNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [interviewJobTitles, setInterviewJobTitles] = useState([]);
  const [selectedJobTitle, setSelectedJobTitle] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedDepartmentId, setSelectedDepartmentId] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [interviewers, setInterviewers] = useState([]);

  const handleNotificationClose = () => {
    setOpenNotification(false);
    setTimeout(() => {
      window.location.reload();  
    }, 500); 
  };

  const handleCreatetDialogOpen = (departmentId,department)=> {
    setSelectedDepartment(department);
    setSelectedDepartmentId(departmentId)
    // console.log(department);
    setCreateDialogOpen(true);
  };

  const handleCreateDialogClose = () => {
    setEditDialogOpen(null);
    setCreateDialogOpen(false);
  };

  const handleEditDialogOpen = (jobTitle) => {
    setSelectedJobTitle(jobTitle);
    setEditDialogOpen(true);
  };

  

  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
    setSelectedJobTitle(null);
  };

 const handleSaveCreateJobInterview = (interviewDetails) =>{
 
  const  birdviewApiUrl = process.env.REACT_APP_BIRDVIEW_API_URL;
  const apiUrl = `${birdviewApiUrl}/api/interview/createInterviewInformation`;
  
    // Send the new data to the backend
    fetch(apiUrl, {
      method: 'POST', // or 'POST' depending on your API
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(interviewDetails),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        // Update the local state with the new job titles
        const updatedTitles = interviewJobTitles.concat(data);
        setInterviewJobTitles(updatedTitles);
        // console.log('New job title created:', data);
        handleCreateDialogClose();
        setNotificationMessage("Job title created successfully!");
        setOpenNotification(true);
      })
      .catch((error) => {
        handleCreateDialogClose();
        // console.error('Error creating job title:', error);
        setNotificationMessage("Failed  to create job title " + error.message); 
        setOpenNotification(true);

        });

  };

 

  const handleSave = (updatedJobTitle) => {
    // Define your API URL
    const birdviewApiUrl = process.env.REACT_APP_BIRDVIEW_API_URL;
    const apiUrl = `${birdviewApiUrl}/api/interview/updateInterviewInformation`;
  
    // Send the updated data to the backend
    fetch(apiUrl, {
      method: 'POST', // or 'POST' depending on your API
      headers: {
        'Content-Type': 'application/json' 
      },
      body: JSON.stringify(updatedJobTitle),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        // Update the local state with the updated job titles
        const updatedTitles = interviewJobTitles.map((title) =>
          title.id === updatedJobTitle.id ? updatedJobTitle : title
        );
        setInterviewJobTitles(updatedTitles);
        handleEditDialogClose();
        setNotificationMessage("Job title updated successfully!");
        setOpenNotification(true);
      })
      .catch((error) => {
        handleEditDialogClose();
        console.error('Error updating job title:', error);
        setNotificationMessage("Failed to update job title.");
        setOpenNotification(true);
      });
  };


  const departmentMapping = {
    1: 'Finance',
    2: 'Underwriting',
    3: 'Business Department',
    4: 'Human Resource & Administration',
    5: 'Operation Claims',
    6: 'IT'
  };

    
  useEffect(() => {
    const birdviewApiUrl = process.env.REACT_APP_BIRDVIEW_API_URL;
    fetch(`${birdviewApiUrl}/api/interview/getAllInterviewJobTitlesInformation`)
      .then(response => response.json())
      .then(data => {
        setInterviewJobTitles(data);
        // console.log('Job titles fetched:', data);
      })
      .catch(error => {
        //console.error("Error fetching job titles:", error);
      });
  }, []); // Empty dependency array ensures this runs only once on mount
  
   const groupedJobTitles = Object.entries(departmentMapping).map(([departmentId, departmentName]) => ({
    department: departmentName,
    departmentId:departmentId,
    titles: interviewJobTitles.filter(jobTitle => jobTitle.departmentId === departmentId) // Compare as strings
  }));
  
  // Only log the result once, not inside any loop
  useEffect(() => {
    // console.log(groupedJobTitles);
  }, [interviewJobTitles]); // Ensure this runs only when interviewJobTitles changes
  
  useEffect(() => {
    const birdviewApiUrl = process.env.REACT_APP_BIRDVIEW_API_URL;

    fetch(`${birdviewApiUrl}/api/interview/getAllInterviewers`)
      .then((response) => response.json())
      .then((data) => {
        setInterviewers(data);
      })
      .catch((error) => {
        // console.error("Error fetching interviewers:", error);
      });
  }, []);

  return (
    <div>
      <div className="text-lg font-semibold flex border-b border-gray-300 justify-center items-center p-1">
        <h1>Settings</h1>
      </div>

      <div className="p-4">
      <h2 className="text-lg font-bold mb-4">Interview Settings</h2>
      {groupedJobTitles?.map(group => (
        <div key={group?.department} className="mb-4">
          <div className="bg-gray-400 mb-2 p-2 flex justify-between">
            <h3 className="text-md font-semibold mb-2">{group?.department}</h3>
            
            <Button  startIcon={<AddToPhotosIcon  />} sx={{ cursor: "pointer", backgroundColor: '#E42D2C2',fontSize:'10px' }}  variant="contained" onClick={() => handleCreatetDialogOpen(group?.department,group?.departmentId,)}  >ADD</Button>
            
          </div>

          {group?.titles?.length > 0 ? (
            <ul>
              {group?.titles.map(title => (
                <li
                  key={title?.id}
                  className="mb-1 flex items-center bg-gray-200 p-2 justify-between"
                >
                  <div className="flex items-center space-x-2">
                    <span>{title?.interviewJobTitle}</span>
                    {title.status?.includes("yes") ? (
                      <>
                        <ToggleOnIcon style={{ color: "green" }} />
                        <span>active</span>
                      </>
                    ) : (
                      <>
                        <ToggleOffIcon style={{ color: "#E42D2C" }} />
                        <span>disabled</span>
                      </>
                    )}
                  </div>

                  <SaveAsIcon
                    variant="contained"
                    onClick={() => handleEditDialogOpen(title)}
                    sx={{ cursor: "pointer", color: '#157EBC' }}
                  />
                </li>
              ))}
            </ul>
          ) : (
            <p>No interviews available</p>
          )}
        </div>
      ))}
    </div>
   

      <AlertDialogSlide
        open={openNotification}
        handleClose={handleNotificationClose}
        message={notificationMessage}
      />

      {selectedJobTitle && (
        <EditInterviewDialog
          open={editDialogOpen}
          handleClose={handleEditDialogClose}
          jobTitle={selectedJobTitle}
          handleSave={handleSave}
          interviewers={interviewers}
        />
      )}

      {selectedDepartment && (
        <CreateJobInterviewDialog
          open={createDialogOpen}
          handleClose={handleCreateDialogClose}
          handleSaveCreateJobInterview={handleSaveCreateJobInterview}
          departmentId={selectedDepartment}
          department={selectedDepartmentId}
       />
      )}

      
    </div>
  );
};

export default Settings;